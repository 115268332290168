
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import PasswordForm from "@/components/forms/components/PasswordForm.vue";
import MemberService from "@/services/members";
import AuthModule from "@/store/auth";
import Cognito from "@/plugins/cognito";
import { authCheck } from "@/utils/Util";

@Component({
  name: "ChangePassword",
  components: {
    Header,
    Footer,
    PasswordForm,
  },
})
export default class ChangePassword extends Vue {
  private password = "";
  private confirmPassword = "";
  private currentPassword = "";
  private id = this.$route.query.code as string;
  private email = "";
  private loading = false;
  private showPass = false;
  private showIncorrectError = false;
  private showLimitChangeError = false;

  private showPassConfirm = false;
  private showPassCurrent = false;

  private get me() {
    return AuthModule.me;
  }

  private async submit() {
    this.loading = true;
    if (this.me) {
      const isMember = await authCheck(this.me.id);
      if (!isMember) {
        Cognito.signOut();
        localStorage.removeItem("vuex");
        this.$router.push({ name: "Login" });
        return;
      }
    } else {
      localStorage.removeItem("vuex");
      this.$router.push({ name: "Login" });
      return;
    }
    Cognito.changePassword(this.currentPassword, this.password, this.callback);
  }

  private async cancel() {
    if (this.me) {
      const isMember = await authCheck(this.me.id);
      if (!isMember) {
        Cognito.signOut();
        localStorage.removeItem("vuex");
        this.$router.push({ name: "Login" });
        return;
      } else {
        localStorage.removeItem("vuex");
        this.$router.push({ name: "Login" });
        return;
      }
    }
    this.$router.push({ name: "MyPage" });
  }

  private showError() {
    this.showIncorrectError = true;
    setTimeout(() => {
      this.showIncorrectError = false;
    }, 3000);
  }

  private showLimitError() {
    this.showLimitChangeError = true;
    setTimeout(() => {
      this.showLimitChangeError = false;
    }, 3000);
  }

  private callback(err: any, result: any) {
    if (err) {
      this.loading = false;
      if (err.message === "Incorrect username or password.") {
        this.showError();
      } else if (err.message === "Attempt limit exceeded, please try after some time.") {
        this.showLimitError();
      }
      throw new Error(err);
    } else {
      this.$router.push({ name: "ChangePasswordComplete" });
      this.loading = false;
    }
  }

  private passwordToggle() {
    this.showPass = !this.showPass;
  }
  private confirmPasswordToggle() {
    this.showPassConfirm = !this.showPassConfirm;
  }
  private currentPasswordToggle() {
    this.showPassCurrent = !this.showPassCurrent;
  }
}
